const CodeMirror = require("codemirror");
const createDOMPurify = require("dompurify");
require("codemirror/mode/htmlmixed/htmlmixed.js");
require("codemirror/mode/css/css.js");
require("pinch-zoom-element/dist/pinch-zoom");

const DOMPurify = createDOMPurify(window);

function getNextTab(tab) {
  if (tab.nextElementSibling) {
    return tab.nextElementSibling;
  } else if (tab.closest(".hp-editor__header-col").nextElementSibling) {
    return tab
      .closest(".hp-editor__header-col")
      .nextElementSibling.querySelector("[role=tab]");
  } else {
    return tab
      .closest(".hp-editor__header-col")
      .previousElementSibling.querySelector("[role=tab]");
  }
}

function getPreviousTab(tab) {
  if (tab.previousElementSibling) {
    return tab.previousElementSibling;
  } else if (tab.closest(".hp-editor__header-col").previousElementSibling) {
    return tab
      .closest(".hp-editor__header-col")
      .previousElementSibling.querySelector("[role=tab]:last-child");
  } else {
    return tab
      .closest(".hp-editor__header-col")
      .nextElementSibling.querySelector("[role=tab]:last-child");
  }
}

class HpEditor extends HTMLElement {
  breakpointWidth = 900;
  codeMirrors = [];

  initializePanel(panel) {
    panel.setAttribute("hidden", true);
    panel.setAttribute("role", "tabpanel");
  }

  initializeTab(tab) {
    tab.setAttribute("role", "tab");
    tab.setAttribute(
      "aria-controls",
      `${tab.getAttribute("data-hp-editor-tab")}-panel`
    );

    tab.addEventListener("click", this.handleTabClick.bind(this));

    tab.addEventListener("keydown", e => {
      if (e.key === "Tab" && !e.shiftKey) {
        // If tabbing into editor panel, disable tab to indent/outdent
        this.codeMirrors.forEach(cm =>
          cm.setOption("extraKeys", { "Shift-Tab": false, Tab: false })
        );
        this.querySelector("[data-hp-editor-panel]:not([hidden])").focus();
      } else {
        switch (e.key) {
          case "ArrowRight":
            getNextTab(tab).focus();
            break;
          case "ArrowLeft":
            getPreviousTab(tab).focus();
            break;
          case "Home":
            tab
              .closest(".hp-editor__header")
              .querySelector("[role=tab]")
              .focus();
            e.preventDefault();
            break;
          case "End":
            tab
              .closest(".hp-editor__header")
              .querySelector(
                ".hp-editor__header-col:last-child [role=tab]:last-child"
              )
              .focus();
            e.preventDefault();
            break;
        }
      }
    });
  }

  initializeTabList(el) {
    el.setAttribute("role", "tablist");
    el.setAttribute("aria-label", el.getAttribute("data-label"));
  }

  initializeTextArea(textarea) {
    const cm = CodeMirror.fromTextArea(textarea, {
      theme: "monokai",
      mode: textarea.getAttribute("data-mode"),
      lineNumbers: true,
      readOnly: textarea.readOnly
    });
    cm.on("change", () => {
      cm.save();
      this.renderPreview(this.form);
    });
    this.codeMirrors.push(cm);

    // Having to refresh after 1 second and hope fonts have loaded to correct cursor position
    // TODO: Look into font loading API
    setTimeout(function() {
      cm.refresh();
    }, 1000);
  }

  handleTabClick(e) {
    const tab = e.target;
    e.preventDefault();
    this.selectTab(tab);
  }

  selectTab(tab) {
    let selectedSelector;
    let panels;

    if (this.is2ColLayout()) {
      const group = tab.getAttribute("data-hp-editor-group");
      selectedSelector = `[aria-selected][data-hp-editor-group=${group}]`;
      panels = this.groupedPanels[group];
    } else {
      selectedSelector = "[aria-selected]";
      panels = this.panels;
    }

    this.querySelectorAll(selectedSelector).forEach(t =>
      t.setAttribute("aria-selected", false)
    );
    tab.setAttribute("aria-selected", true);

    panels.forEach(panel => {
      if (panel.id === tab.getAttribute("aria-controls")) {
        panel.removeAttribute("hidden");
      } else {
        panel.setAttribute("hidden", "");
      }
    });

    this.codeMirrors.forEach(cm => cm.refresh());
  }

  is2ColLayout() {
    return this.offsetWidth > this.breakpointWidth;
  }

  setLayout(cb) {
    const currentIs2Col = this.classList.contains("hp-editor--2col");
    const nextIs2Col = this.is2ColLayout();

    if (currentIs2Col === nextIs2Col) return;

    if (nextIs2Col) {
      this.classList.add("hp-editor--2col");
    } else {
      this.classList.remove("hp-editor--2col");
    }

    cb && cb();
  }

  selectDefaultTabs() {
    if (this.is2ColLayout()) {
      this.selectTab(this.groupedTabs.code[0]);
      this.selectTab(this.groupedTabs.preview[0]);
    } else {
      this.selectTab(this.tabs[0]);
    }
  }

  handleResize() {
    this.setLayout(() => {
      this.selectDefaultTabs();
    });
  }

  renderPreview(form) {
    const { html, css } = form.elements;
    const cleanHtml = DOMPurify.sanitize(html.value);

    this.querySelectorAll(".hp-editor__preview-frame").forEach(frame => {
      const doc = frame.contentWindow.document;
      doc.body.innerHTML = cleanHtml;
      doc.getElementById("styles").innerHTML = css.value;
    });
  }

  init() {
    this.form = this.closest("form");
    this.panels = this.querySelectorAll("[data-hp-editor-panel]");
    this.querySelectorAll(".hp-editor__textarea").forEach(
      this.initializeTextArea.bind(this)
    );
    this.groupedPanels = {
      code: this.querySelectorAll(
        "[data-hp-editor-panel][data-hp-editor-group=code]"
      ),
      preview: this.querySelectorAll(
        "[data-hp-editor-panel][data-hp-editor-group=preview]"
      )
    };

    this.tabs = this.querySelectorAll("[data-hp-editor-tab]");
    this.groupedTabs = {
      code: this.querySelectorAll(
        "[data-hp-editor-tab][data-hp-editor-group=code]"
      ),
      preview: this.querySelectorAll(
        "[data-hp-editor-tab][data-hp-editor-group=preview]"
      )
    };

    this.querySelectorAll(".hp-tablist").forEach(
      this.initializeTabList.bind(this)
    );
    this.panels.forEach(this.initializePanel.bind(this));
    this.tabs.forEach(this.initializeTab.bind(this));
    this.setLayout();
    this.selectDefaultTabs();
    // // should throttle
    window.addEventListener("resize", this.handleResize.bind(this));
    this.classList.add("hp-editor--enhanced");

    window.addEventListener("message", message => {
      const parsedMessage = JSON.parse(message.data);
      switch (parsedMessage.type) {
        case "svg":
          this.form.elements.svgImage.value = parsedMessage.dataUrl;
          break;
      }
    });

    this.renderPreview(this.form);
  }

  constructor(...args) {
    super(...args);
    this.init();
  }
}

class HpPreview extends HTMLElement {
  zoomIn() {
    this.pinchZoom.scaleTo(this.pinchZoom.scale + 0.1, {
      originX: "50%",
      originY: "50%"
    });
  }

  zoomOut() {
    this.pinchZoom.scaleTo(this.pinchZoom.scale - 0.1, {
      originX: "50%",
      originY: "50%"
    });
  }

  init() {
    const zoomInBtn = this.querySelector('[data-zoom="in"]');
    const zoomOutBtn = this.querySelector('[data-zoom="out"]');
    this.pinchZoom = this.querySelector("pinch-zoom");
    this.pinchZoom.addEventListener("wheel", e => e.stopPropagation(), true);
    this.pinchZoom.addEventListener("change", e => e.stopPropagation(), true);
    zoomInBtn.addEventListener("click", this.zoomIn.bind(this));
    zoomOutBtn.addEventListener("click", this.zoomOut.bind(this));
  }

  constructor(...args) {
    super(...args);
    this.init();
  }
}

window.addEventListener("load", () => {
  customElements.define("hp-editor", HpEditor);
  customElements.define("hp-preview", HpPreview);
});
